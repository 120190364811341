/* eslint-disable import/no-anonymous-default-export */
import dotenv from "dotenv";
// require('dotenv').config();

if (!dotenv) {
  throw new Error("Unable to use dot env lib");
}

// Set the NODE_ENV to 'development' by default
// process.env.NODE_ENV = process.env.NODE_ENV || 'development';

const envFound = dotenv.config();
if (!envFound) {
  // This error should crash whole process
  throw new Error("⚠️ Couldn't find .env file ⚠️");
}

const defaultConst = {
  platform: "web",
  accessToken: "",
  fileSize: 5,
  fileSizeMB: 5 * 1024,
  fileSizeKB: 5 * 1024 * 1024,
  SUPPORTED_FORMATS: ["jpg", "jpeg", "png", "pdf"],
};
export default defaultConst;

export const BaseUrl = process.env.REACT_APP_BASE_URL;

// export const BaseUrl = "http://localhost/React/LINP/linp-backend-api/" // local image path
let BASE_FILE_URL;
if (!process.env.REACT_APP_BASE_FILE_URL) {
  BASE_FILE_URL = "https://linp.s3.us-west-1.amazonaws.com/linp-upload/";
} else {
  BASE_FILE_URL = process.env.REACT_APP_BASE_FILE_URL;
}

let image_url: string;
let userBaseProfileURL: string;
let userLicenceURL: string;
let ASSET_IMAGE_URL: string;
let ASSET_PUBLIC_URL: string;
let ASSET_VIDEO_URL: string;
let userBlogURL: string;
let userBannerLocation: string;
let activityImagesBaseURL: string;

// BLOG_IMAGE_PATH=/upload/users/blogs
// /users/location/banner
if (!process.env.REACT_APP_BASE_FILE_URL) {
  image_url = `${BaseUrl}public/upload/`;
  userBaseProfileURL = `${image_url}users/profile/`;
  activityImagesBaseURL = `${image_url}users/activity/`;
  userBannerLocation = `${image_url}location/banner/`;
  userLicenceURL = `${image_url}users/license/`;
  ASSET_IMAGE_URL = `${BASE_FILE_URL}dev/assets/images`;
  ASSET_VIDEO_URL = `${BASE_FILE_URL}dev/assets/video`;
  ASSET_PUBLIC_URL = `${BASE_FILE_URL}dev/assets/public`;
  userBlogURL = `${BASE_FILE_URL}user/blogs`;
} else if (process.env.REACT_APP_NODE_ENV === "development") {
  image_url = `${BASE_FILE_URL}dev/user/`;
  userBaseProfileURL = `${image_url}profile/`;
  activityImagesBaseURL = `${image_url}activity/`;
  userBannerLocation = `${image_url}location/banner/`;
  userLicenceURL = `${image_url}license/`;
  ASSET_IMAGE_URL = `${BASE_FILE_URL}dev/assets/images`;
  ASSET_VIDEO_URL = `${BASE_FILE_URL}dev/assets/video`;
  ASSET_PUBLIC_URL = `${BASE_FILE_URL}dev/assets/public`;
  userBlogURL = `${BASE_FILE_URL}dev/user/blogs`;
} else if (process.env.REACT_APP_NODE_ENV === "production") {
  image_url = `${BASE_FILE_URL}live/user/`;
  userBaseProfileURL = `${image_url}profile/`;
  activityImagesBaseURL = `${image_url}activity/`;
  userBannerLocation = `${image_url}location/banner/`;
  userLicenceURL = `${image_url}license/`;
  ASSET_IMAGE_URL = `${BASE_FILE_URL}live/assets/images`;
  ASSET_VIDEO_URL = `${BASE_FILE_URL}live/assets/video`;
  ASSET_PUBLIC_URL = `${BASE_FILE_URL}live/assets/public`;
  userBlogURL = `${BASE_FILE_URL}live/user/blogs`;
} else if (process.env.REACT_APP_NODE_ENV === "staging") {
  image_url = `${BASE_FILE_URL}staging/user/`;
  userBaseProfileURL = `${image_url}profile/`;
  activityImagesBaseURL = `${image_url}activity/`;
  userBannerLocation = `${image_url}location/banner/`;
  userLicenceURL = `${image_url}license/`;
  ASSET_IMAGE_URL = `${BASE_FILE_URL}staging/assets/images`;
  ASSET_VIDEO_URL = `${BASE_FILE_URL}staging/assets/video`;
  ASSET_PUBLIC_URL = `${BASE_FILE_URL}staging/assets/public`;
  userBlogURL = `${BASE_FILE_URL}staging/user/blogs`;
} else {
  image_url = `${BASE_FILE_URL}dev/user/`;
  userBaseProfileURL = `${image_url}profile/`;
  activityImagesBaseURL = `${image_url}activity/`;
  userBannerLocation = `${image_url}location/banner/`;
  userLicenceURL = `${image_url}license/`;
  ASSET_IMAGE_URL = `${BASE_FILE_URL}dev/assets/images`;
  ASSET_VIDEO_URL = `${BASE_FILE_URL}dev/assets/video`;
  ASSET_PUBLIC_URL = `${BASE_FILE_URL}dev/assets/public`;
  userBlogURL = `${BASE_FILE_URL}dev/user/blogs`;
}

const countryRegion = "us"; // US = United States region for zip code autofill

export {
  image_url,
  userBaseProfileURL,
  activityImagesBaseURL,
  userLicenceURL,
  ASSET_IMAGE_URL,
  ASSET_PUBLIC_URL,
  ASSET_VIDEO_URL,
  userBlogURL,
  countryRegion,
  userBannerLocation,
};

export const GOOGLE_MAP_KEY = "AIzaSyAkx20_pU3oNrh2pHml5vaXiGltwZIcow4";

export const GOOGLE_MAP_CENTER = {
  // california
  // lat: 36.7783,
  // lng: -119.4179
  lat: 33.04660097409988,
  lng: -117.2572885311544,
};
// export const PUBLIC_URL = "http://192.168.42.141:3000"
export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

/**
 * Status code
 * 0) Under review (Pending) -> location added -> not approved by admin
 * 1) Active: -> location added -> approved by admin -> payment done
 * 2) In queue: -> per zipcode and per speciality only three practitioner will be active
 * 4th one will be "In queue"
 * 3) Incomplete: -> If practitioner profile is not completed and verified
 * 4) Pay now: -> location added -> approved by admin -> payment not done
 * Location with this status are only to be shown at practitioner dashboard (not at admin side)
 * 5) Suspended: -> If suspended by admin
 * 6) Rejected: -> If rejected by admin
 * 7) Deleted: -> if deleted by practitioner
 * 8) Cancelled: -> if Cancelled by practitioner
 * 9) Payment failed: -> if payment failed when auto payment proceeded
 */

export const monthOptions = [
  { value: "01", label: "Jan" },
  { value: "02", label: "Feb" },
  { value: "03", label: "Mar" },
  { value: "04", label: "Apr" },
  { value: "05", label: "May" },
  { value: "06", label: "Jun" },
  { value: "07", label: "Jul" },
  { value: "08", label: "Aug" },
  { value: "09", label: "Sep" },
  { value: "10", label: "Oct" },
  { value: "11", label: "Nov" },
  { value: "12", label: "Dec" },
];

const yearOptions: any = [];

export const PAYMENT_EXPIRY_YEARS = 10;
let year = new Date();
for (
  let i = year.getFullYear();
  i < year.getFullYear() + PAYMENT_EXPIRY_YEARS;
  i++
) {
  yearOptions.push({ value: i, label: i });
}

export { yearOptions };

export const AES_SECRET_KEY = "@es-$ecretke#L!NP-@#&";

export const SEARCH_RESULT_LIMIT = 10;

export const ACTIVITY_RESULT_LIMIT = 10;

export const DROP_DOWN_LIMIT = 20;

export const HOME_PAGE_VIDEO_URL = `${ASSET_VIDEO_URL}/zipally.mp4`;

export const HOME_PAGE_VIDEO_LARGE = `${ASSET_VIDEO_URL}/zipally.mp4`;

export const HOME_PAGE_VIDEO_SMALL = `${ASSET_VIDEO_URL}/zipally.mp4`;

// export const BLOG_URL = 'https://medium.com/@localinjurynetwork';
export const BLOG_URL = "/blog";

export const WEBSITE_LINK = "https://zipally.com/";

/**
 * Activity
 */
export const activityType = {
  1: "Post",
  2: "Promotion",
  3: "Event",
};

export const activityTypeInt = {
  Post: 1,
  Promotion: 2,
  Event: 3,
};

export const activityImageLimit: any = {
  1: 5,
  2: 5,
  3: 5,
};

export const eventType = {
  online: "online",
  offline: "offline",
};

export const activityImageHW = {
  width: 500,
  height: 250,
};

export const profilePartialForm = {
  personalInformation: "personalInformation",
  qualification: "qualification",
  certifications: "certifications",
  socialMediaInformation: "SocialMediaInformation",
  zipcodeServed: "zipcodeServed",
};

export const groupType = {
  global: "global",
  zipcode: "zipcode",
  individual: "individual",
};

export const transactionType = {
  1: "Subscription",
  2: "Sponsor Profile",
  3: "Subscription Refund",
};

export const sponsorProfileRate = 9.99;
export const subscriptionPlan = {
  monthly: 24.99,
  yearly: 239.88,
};

/** Referral Code Discount type */
export const referralCodeDiscountType = {
  Flat: 0,
  Percentage: 1,
};

/** Referral Code Discount type */
export const referralCodeApplicable = {
  Monthly: 0,
  Yearly: 1,
};
